var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "mb-4",
              staticStyle: { color: "white" },
              attrs: { cols: "12", md: "4" }
            },
            [
              _c(
                "div",
                { staticClass: "text-left py-1" },
                [
                  _c(
                    "router-link",
                    { staticClass: "mx-2", attrs: { to: "/admin/employee" } },
                    [_vm._v("Employees")]
                  ),
                  _vm._v(" / "),
                  _c(
                    "span",
                    {
                      staticClass: "mx-2",
                      staticStyle: { color: "lightgrey" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.user.firstname) +
                          " " +
                          _vm._s(_vm.user.lastname) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", md: "4" } },
            [
              _vm.user.active == 1
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "danger", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.disable()
                        }
                      }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "x-circle" } }),
                      _vm._v(" Disable ")
                    ],
                    1
                  )
                : _c(
                    "b-button",
                    {
                      attrs: { variant: "info", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.enable()
                        }
                      }
                    },
                    [
                      _c("b-icon", { attrs: { icon: "arrow-clockwise" } }),
                      _vm._v(" Re-activate ")
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", md: "4" } },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "success", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.resetPassword()
                    }
                  }
                },
                [
                  _c("b-icon", { attrs: { icon: "key-fill" } }),
                  _vm._v(" Reset Password ")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "UserDetails",
        { attrs: { user: this.user } },
        [
          [
            _c("EditUser", {
              attrs: { id: +_vm.id, edit: "" },
              on: {
                refresh: function($event) {
                  return _vm.init()
                }
              }
            })
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }