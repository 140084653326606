var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      staticClass: "mb-4 text--black",
      staticStyle: { "text-align": "left" },
      scopedSlots: _vm._u(
        [
          {
            key: "header",
            fn: function() {
              return [
                _c("div", { staticClass: "d-inline h4" }, [_vm._v("Details")]),
                _c(
                  "div",
                  { staticClass: "float-right" },
                  [_vm._t("default")],
                  2
                )
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c("b", [_vm._v("First Name:")]),
            _vm._v(" " + _vm._s(_vm.user.firstname) + " ")
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c("b", [_vm._v("Last Name:")]),
            _vm._v(" " + _vm._s(_vm.user.lastname) + " ")
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c("b", [_vm._v("Email:")]),
            _vm._v(" " + _vm._s(_vm.user.email) + " ")
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c("b", [_vm._v("Last Login:")]),
            _vm._v(
              " " +
                _vm._s(_vm.user.lastlogin ? _vm.user.lastlogin : "Never") +
                " "
            )
          ]),
          _c("b-col", { attrs: { cols: "12", sm: "6", md: "4" } }, [
            _c("b", [_vm._v("Needs Password Change:")]),
            _vm._v(
              " " + _vm._s(_vm.user.forcepwchange == 1 ? "Yes" : "No") + " "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }